"use client"
import _ from "lodash";
import localStorageCall from "./localstorage.hook";
import { createContext } from "react";
import moment from "moment";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import CurrencyFormat from "react-currency-format";
import { JapanStates } from "./commonPayload";

export const ContextData = createContext();

export function _scrollToUp() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function _dateFormatChange(date, format) {
    return moment(date).format(format).toLocaleString();
}

/****** current store of product ******/
export function storeAssigned() {
    const CURRENT_STORE = localStorageCall().getSingleItem('store') || 'us';
    return CURRENT_STORE;
}

export const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr') ? localStorageCall().getItem('customerTypeDescr') : { priceType: 1, customerType: 'Retail Customer (RC)' };
export const MIDDLE_CREDENTIAL = { 'Content-Type': 'multipart/form-data' };
export const CacheHeader = { 'Cache-control': 'no-cache' };
export const REFFERAL_VALUES = localStorageCall().getItem('refferal_link');  /*** get Refferal value from cookiee ***/
export const ButtonCssClass = "relative f-q-heavy text-xs sm:text-[8px] md:text-[10px] lg:text-[11px] xl:text-[18px] w-[110px] h-[30px] sm:w-[65px] sm:h-[25px] md:w-[85px] md:h-[26px] lg:w-[105px] lg:h-[28px] xl:w-[140px] xl:h-[40px]  pt-0.5 z-10 inline-block rounded-full border-2 bg-[#10938c] border-[#10938c] text-white hover:bg-white hover:text-[#10938c] transition-colors duration-200";


export const isUserLogin = localStorageCall().getItem('Token');

export function _setTimeOut(callback, seconds = 3000) {   /****** setTime out function ******/
    setTimeout(() => callback(), seconds);
}
export const ErrorDisplay = ({ message }) => (<span className='text-base text-red-500' style={{ color: "error" }}>{message}</span>);   /**** display error message ****/

export const SuccessDisplay = ({ message }) => (<span className='text_sucess' style={{ color: "green", display: "flex", textAlign: "center" }}>{message}</span>);  /**** display success message ****/

export const FloatValues = (value, count = 2) => {
    if (_.upperCase(storeAssigned()) === 'JP') {

        return parseFloat(value);

    } else if (!_.includes(String(value), '.')) {
        return parseFloat(value)?.toFixed(count);
    } else {
        const roundedValue = Math.floor(parseFloat(value) * 100) / 100;
        return roundedValue.toFixed(count);
    }
};;

// filter products
export const filterProducts = (options, searchText) => {
    return options?.filter((option) => option[label].toLowerCase().indexOf(searchText.toLowerCase()) > -1);
};

export const CurrencyConverter = ({ value }) => (
    <CurrencyFormat value={value} decimalScale={_.upperCase(storeAssigned()) === 'JP' ? 0 : 2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} renderText={(value) => value} />
)

/****** logout user function ******/
export function _logoutFunction(status = "redirect", userData) {
    // console.log('run');
    if (status === 'redirect') {
        const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
        const userdata = JSON.stringify({ "id": userData?.customerId, "name": userData?.fullName, "webalies": userData?.webAlias });
        if (CUSTOMER_TYPE?.role !== 'retail' && userData?.webAlias) {
            // localStorageCall().setItem('refferal_link', userdata);
        }
    }
    localStorageCall().removeItem('Token');
    localStorageCall().removeItem('alluserData');
    localStorageCall().removeItem('customerTypeDescr');
    localStorageCall().removeItem('TreeMoveData');
    localStorageCall().removeItem('Dates');
    localStorageCall().removeItem('RenewalProduct');
    if (status === "redirect") {
        setTimeout(() => window.location.assign(`/signin`), 1000);
    }
}

// form 
export function _getPrice(row, id) {
    return _.find(row?.itemPricing, (row) => (row?.countryCode === `${storeAssigned().toUpperCase()}` && row?.priceType === id))?.itemPrice
}

export function _getCvValue(row, id) {
    return _.find(row?.itemPricing, (row) => (row?.countryCode === `${storeAssigned().toUpperCase()}` && row?.priceType === id))?.cv
}


export function _convertStringKeyValueInObject(errorMessage) {
    const objectData = {};
    _.forEach(_.compact(errorMessage?.split("\n")), (value) => {
        const INNER_SPLIT = _.split(value, ":");
        _.assign(objectData, { [_.lowerCase(INNER_SPLIT[0])?.replaceAll(/\s/g, "_")]: INNER_SPLIT[1]?.trim() });
    });
    return objectData;
}
export const CheckboxClass = 'cursor-pointer inline-block relative text-base leading-[1.2] peer-checked:after:opacity-[1] peer-checked:before:border-[#000] peer-checked:before:bg-[#000] before:-content-[""] before:inline-block before:align-middle before:transition-all before:ease-in-out before:duration-300 before:mr-1 before:border-[2px] before:border-clr before:h-[24px] before:w-[24px] before:rounded-[3px] after:content-[""] after:transition-all after:ease-in-out after:duration-300 after:h-[7px] after:w-[14px] after:border-l-[2px] after:border-b-[2px] after:border-white after:absolute after:left-[5px] after:top-[7px] after:-rotate-[45deg] after:opacity-0'

export const MiniLoader = () => <div className="h-8 w-8 animate-spin rounded-full mx-auto border-4 border-solid border-button-color border-t-transparent"></div>;

export function cn(...twclasses) {
    return twMerge(clsx(twclasses));
}

//formdata_converter
export function _getFormDataConvert(valuesObj) {
    const data = _.entries(valuesObj);
    const formdata = new FormData();
    _.forEach(data, ([key, value]) => { formdata.append(key, value); })
    return formdata;
}

export function _pointsPrice(priceList, selectedPrice, isEligibleForAutoOrder) {
    const CUSTOMER_TYP = localStorageCall().getItem('customerTypeDescr');
    let bb = (CUSTOMER_TYP?.priceType && CUSTOMER_TYP?.priceType !== 1 && isEligibleForAutoOrder) ? CUSTOMER_TYP?.priceType : (selectedPrice === "autoship" && isEligibleForAutoOrder) ? 5 : 1;
    const PRICE = _.find(priceList, ({ countryCode, priceType }) => {
        return (countryCode === _.upperCase(storeAssigned()) && priceType === bb)
    })?.points || 0;
    return PRICE;
}


/****** Product price based on customer  profile type ******/
export function _productPrice(priceList, selectedPrice, isEligibleForAutoOrder) {
    const CUSTOMER_TYP = localStorageCall().getItem('customerTypeDescr') || {};
    // console.log(CUSTOMER_TYP?.priceType, isEligibleForAutoOrder);
    let bb = (CUSTOMER_TYP?.priceType && CUSTOMER_TYP?.priceType !== 1 && isEligibleForAutoOrder) ? CUSTOMER_TYP?.priceType : (selectedPrice === "autoship" && isEligibleForAutoOrder) ? 5 : 1;
    const PRICE = _.find(priceList, ({ countryCode, priceType }) => {
        // console.log(priceList, priceType, countryCode, 'PRICE >> >> > >> > > > > >> > ')
        return (countryCode === _.upperCase('us') && priceType === bb)
    })?.itemPrice || 0;
    return FloatValues(PRICE);
}

export function _googleAddressSetup(results, callback = null) {
    if (results) {
        let ADDRESS = {};
        for (const component of results.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];
            switch (componentType) {
                case "street_number":
                    ADDRESS['shipping_street'] = `${component.long_name}`;
                    break;
                case "route":
                    ADDRESS['shipping_street'] = `${ADDRESS?.shipping_street || ''} ${component.short_name}`;
                    break;
                case "postal_code":
                    ADDRESS['shipping_zipcode'] = `${component.long_name}`;
                    break;
                // case "postal_code_suffix":
                //     ADDRESS['shipping_zipcode'] = `${ADDRESS?.shipping_zipcode || ''}-${component.long_name}`;
                //     break;
                case "locality":
                    ADDRESS['shipping_city'] = component.long_name;
                    break;
                case "administrative_area_level_1":
                    ADDRESS['shipping_state'] = _.upperCase(storeAssigned()) === 'JP' ? JapanStates[component.short_name] : component.short_name;
                    break;
                case "country":
                    ADDRESS['shipping_country'] = component.short_name;
                    break;
            }
        }
        if (callback) {
            callback(ADDRESS)
        }

    }
}

// export const getCurrencySign = () => {
//     const CURRENT_STORE = _.upperCase(storeAssigned()) || countryCode;
//     switch (CURRENT_STORE) {
//         case 'US':
//             return '$';
//         case 'CA':
//             return '$';
//         case 'GB':
//             return '$';
//         case 'AU':
//             return '$';
//         case 'NZ':
//             return '$';
//         case 'MX':
//             return '$';
//         case 'NG':
//             return '$';
//         case 'PH':
//             return '$';
//         case 'CD':
//             return '$';
//         case 'CM':
//             return '$';
//         case 'KE':
//             return '$';
//         case 'MU':
//             return '$';
//         default:
//             return '$';
//     }
// };

export const getCurrencySign = () => {
    const CURRENT_STORE = _.upperCase(storeAssigned()) || countryCode;

    switch (CURRENT_STORE) {
        case 'US':
        case 'CA':
        case 'AU':
        case 'NZ':
        case 'MX':
        case 'NG':
        case 'PH':
        case 'CD':
        case 'CM':
        case 'KE':
        case 'MU':
        case 'BS':
        case 'BR':
        case 'HK':
        case 'ID':
        case 'JM':
        case 'MY':
        case 'PA':
        case 'PE':
        case 'NZ':
        case 'SG':
        case 'TW':
        case 'CO':
        case 'GH':
        case 'MZ':
        case 'NA':
        case 'ZA':
        case 'ZM':
        case 'ZW':
        case 'BW':
        case "TZ":
        case "UG":
        case "IN":
            return '$';
        // case 'GB':
        //     return '£';
        case 'AT':
        case 'BE':
        case 'BG':
        case 'CH':
        case 'CY':
        case 'CZ':
        case 'DE':
        case "DK":
        case "EE":
        case "ES":
        case "FI":
        case "FR":
        case "GB":
        case "GR":
        case "HR":
        case "HU":
        case "EI":
        // case "IN":
        case "IS":
        case "IT":
        case "IS":
        case "LT":
        case "LU":
        case "LV":
        case "MT":
        case "NC":
        case "NL":
        case "NO":
        case "PL":
        case "RE":
        case "RO":
        case "RS":
        case "RU":
        case "SE":
        case "SI":
        case "SK":
        case "RU":
        case "RU":
        case 'PF':
            return '€';
        case 'JP':
            return '¥';
        // case 'IN':
        //     return '₹';
        // case 'KR':
        //     return '₩';
        // case 'RU':
        //     return '₽';
        // case 'VN':
        //     return '₫';
        // // case 'ID':
        // //     return 'Rp';
        // case 'TH':
        //     return '฿';
        // case 'ZA':
        //     return 'R';
        // // case 'BR':
        // //     return 'R$';
        // case 'CH':
        //     return 'CHF';
        // case 'SE':
        //     return 'kr';
        // case 'DK':
        //     return 'kr';
        // case 'NO':
        //     return 'kr';
        // case 'PL':
        //     return 'zł';
        // case 'CZ':
        //     return 'Kč';
        // case 'IL':
        //     return '₪';
        // case 'SG':
        //     return 'S$';
        default:
            return '$';
    }
};

export const getCurrencyCode = (countryCode) => {
    const CURRENT_STORE = countryCode || _.upperCase(storeAssigned());
    switch (CURRENT_STORE) {
        case 'US':
            return 'USD';
        case 'AU':
            return 'USD';
        case 'BR':
            return 'USD';
        case 'BS':
            return 'USD';
        case 'CA':
            return 'USD';
        case 'HK':
            return 'USD';
        case 'ID':
            return 'USD';
        case 'JM':
            return 'USD';
        case 'MY':
            return 'USD';
        case 'PA':
            return 'USD';
        case 'PE':
            return 'USD';
        case 'NZ':
            return 'USD';
        case 'PH':
            return 'USD';
        case 'SG':
            return 'USD';
        case 'TW':
            return 'USD';
        case 'SG':
            return 'USD';
        case 'GH':
            return 'USD';
        case 'BW':
            return 'USD';
        case 'KE':
            return 'USD';
        case 'MZ':
            return 'USD';
        case 'NG':
            return 'USD';
        case 'NA':
            return 'USD';
        case 'ZA':
            return 'USD';
        case 'ZM':
            return 'USD';
        case 'ZW':
            return 'USD';
        case 'MX':
            return 'MXN';
        case 'TZ':
            return 'USD';
        case 'IN':
            return 'USD';
        case 'UG':
            return 'USD';
        case 'JP':
            return 'JPY';
        case 'CO':
            return 'COP';
        case 'AT':
        case 'BE':
        case 'BG':
        case 'CH':
        case 'CY':
        case 'CZ':
        case 'DE':
        case 'DK':
        case 'EE':
        case 'ES':
        case 'FI':
        case 'FR':
        case 'GB':
        case 'GR':
        case 'HR':
        case 'HU':
        case 'EI':
        // case 'IN':
        case 'IS':
        case 'IT':
        case 'LT':
        case 'LU':
        case 'LV':
        case 'MT':
        case 'NC':
        case 'NL':
        case 'NO':
        case 'PL':
        case 'RE':
        case 'RO':
        case 'RS':
        case 'RU':
        case 'SE':
        case 'SI':
        case 'SK':
        case 'PF':
            return 'EUR';
        default:
            return 'USD';
    }
};



// export const getCurrencyCode = () => {
//     const CURRENT_STORE = _.upperCase(storeAssigned()) || countryCode;
//     switch (CURRENT_STORE) {
//         case 'US':
//             return 'USD';
//         case 'CA':
//             return 'USD';
//         case 'GB':
//             return 'USD';
//         case 'AU':
//             return 'USD';
//         case 'NZ':
//             return 'USD';
//         case 'MX':
//             return 'MXN';
//         case 'NG':
//             return 'USD';
//         case 'PH':
//             return 'USD';
//         case 'CD':
//             return 'USD';
//         case 'CM':
//             return 'USD';
//         case 'KE':
//             return 'USD';
//         case 'MU':
//             return 'USD';
//         case 'JP':
//             return 'JPY';
//         case 'CO':
//             return 'COP';
//         case 'NL':
//         case 'AT':
//         case 'EU':
//         case 'FR':
//         case 'DE':
//         case 'ES':
//         case 'IT':
//         case 'IE':
//         case 'BE':
//         case 'CH':
//         case 'CY':
//         case 'EE':
//         case 'FI':
//         case 'GR':
//         case 'HR':
//         case 'LT':
//         case 'LU':
//         case 'LV':
//         case 'MT':
//         case 'PT':
//         case 'RE':
//         case 'SI':
//         case 'SK':
//             return 'EUR';
//         case 'BG':
//             return 'BGN';
//         case 'BR':
//             return 'BRL';
//         case 'BS':
//             return 'BSD';
//         case 'BW':
//             return 'BWP';
//         case 'CH':
//             return 'CHF';
//         case 'CZ':
//             return 'CZK';
//         case 'DK':
//             return 'DKK';
//         case 'UK':
//             return 'GBP';
//         case 'GH':
//             return 'GHS';        
//         case 'HK':
//             return 'HKD';
//         case 'HU':
//             return 'EUF';
//         case 'ID':
//             return 'IDR';
//         case 'IS':
//             return 'ISK';
//         case 'JM':
//             return 'JMD';
//         case 'KE':
//             return 'KES';
//         case 'MY':
//             return 'MYR';
//         case 'MZ':
//             return 'MZ';
//         case 'MZ':
//             return 'MZN';
//         case 'NA':
//             return 'NAD';
//         case 'NC':
//             return 'XPF';
//         case 'NG':
//             return 'NGN';
//         case 'NO':
//             return 'NOK';
//         case 'NZ':
//             return 'NZD';
//         case 'PH':
//             return 'PHP';
//         case 'PE':
//             return 'PEN';
//         case 'PL':
//             return 'PLN';
//         case 'RO':
//             return 'RON';
//         case 'RS':
//             return 'RSD';
//         case 'RU':
//             return 'RUB';
//         case 'SE':
//             return 'SEK';
//         case 'SG':
//             return 'SGD';
//         case 'TW':
//             return 'TWD';
//         case 'TZ':
//             return 'TZS';
//         case 'UG':
//             return 'UGX';
//         case 'ZA':
//             return 'ZAR';
//         case 'ZM':
//             return 'ZMW'
//         case 'ZW':
//             return 'ZWL'
//         default:
//             return 'USD';
//     }
// };



export const getCurrencySymbol = (countryCode) => {
    try {
        const currencyFormatter = new Intl.NumberFormat(`en-${countryCode}`, {
            style: "currency",
            currency: new Intl.Locale(`en-${countryCode}`).maximize().currency || "USD",
            minimumFractionDigits: 0,
        });
        const parts = currencyFormatter.formatToParts(1);
        const currencySymbol = parts.find((part) => part.type === "currency")?.value;
        return currencySymbol || "no syemble";
    } catch (error) {
        console.error("Error fetching currency symbol:", error);
        return "Invalid Country Code";
    }
};


// function _getCurrentCatId(catId, section) {
//     const COUNTRYID = { us: 1000, ca: 1000 };

//     let id = catId || COUNTRYID[storeAssigned()];
//     switch (section) {
//       case 'All Products':
//         id = 1000;
//         break;
//     }
//     return id;
//   }

export function _getStoreFrontId(section) {
    console.log("section==============", section);
    const AllPRODUCTSCOUNTRYID = {
        1: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg'],
        1002: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
        1000: ['jp'],
        1001: ['co'],
        1003: ['mx']
    };

    const getId = (countryCode, idMap) => {
        for (const [id, countries] of Object.entries(idMap)) {
            if (countries.includes(countryCode)) {
                return id;
            }
        }
        return 1000;
    }
    let id = 1;

    switch (section) {
        case 'All Products':
            id = getId(storeAssigned(), AllPRODUCTSCOUNTRYID);
            break;
        default:
            id = 1;
            break;
    }

    return id;
}
